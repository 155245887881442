import './App.css';
// import VersionInfo from './VersionInfo';
// import GithubWidgets from './GithubWidgets';
import LayoutHeader from './Layout/Header';

import logoJS from './assets/icon-js.png';
import logoTS from './assets/icon-ts.png';

function App() {

  return (
    <div className="App">
      <LayoutHeader />

      <div class="App-content">
        <h2>💻 Open-source projects:</h2>

        <p>
          <span class="title">Greenlight</span> <img src={logoTS} title="Typescript" alt="Typescript" class="lang-icon" /> <br />

          Electron application that allows you to Stream your Xbox One / Xbox Series console to your computer. Works on Windows, OSX and Linux.<br />

          <a href="https://github.com/unknownskl/greenlight" class="btn" target="_blank" rel="noreferrer">Open on Github</a>
        </p>

        <p>
          <span class="title">xbox-xcloud-player</span> <img src={logoTS} title="Typescript" alt="Typescript" class="lang-icon" /> <br />

          Xbox-Xcloud-Player is a library that can connect to an xCloud / xHome stream using WebRTC and provides an API interface for controlling the stream. Mainly developed to separate the player from Greenlight.<br />

          <a href="https://github.com/unknownskl/xbox-xcloud-player" class="btn" target="_blank" rel="noreferrer">Open on Github</a>
        </p>

        <p>
          <span class="title">homebridge-smartglass</span> <img src={logoTS} title="Typescript" alt="Typescript" class="lang-icon" /> <br />

          Homebridge plugin that adds support for the Xbox console. Allows you to turn on, off and control using the build-in iOS remote.<br />
          
          <a href="https://github.com/unknownskl/homebridge-smartglass" class="btn" target="_blank" rel="noreferrer">Open on Github</a>
        </p>

        <p>
          <span class="title">xbox-smartglass-core-node</span> <img src={logoJS} title="Javascript" alt="Javascript" class="lang-icon" /> <br />

          NodeJS library for controlling an Xbox over the Smartglass Protocol.<br />
          
          <a href="https://github.com/OpenXbox/xbox-smartglass-core-node" class="btn" target="_blank" rel="noreferrer">Open on Github</a>
        </p>

        <h2>📨 Contact me:</h2>

        <p class="nopadding">
          <a href="https://www.linkedin.com/in/jim-kroon-01953b17/" class="btn" target="_blank" rel="noreferrer">LinkedIn</a> &nbsp; 
          <a href="https://github.com/unknownskl" class="btn" target="_blank" rel="noreferrer">Github</a>
        </p>
      </div>

      {/* <GithubWidgets /> */}
      {/* <VersionInfo /> */}
    </div>
  );
}

export default App;
