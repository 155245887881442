import React from 'react';
// import './LayoutHeader.css';

class LayoutHeader extends React.Component {
//   constructor(props) {
//     super(props);
//   }

  render(){
    return (
        <header className="App-header">
            👋 Hi! My name is Jim Kroon<br />
        </header>
    );
  }
}

export default LayoutHeader;
